import React, { useEffect } from "react";
import Layout from "../components/layout/layout";
import { ImageBackground, MainDiv } from "../components/styledComponents";
import { css } from "@emotion/react";
import Image from "../components/image";
import { StaticImage } from "gatsby-plugin-image";
import { motion } from "framer-motion";
import useWindowSize from "../util/useWindowSize";

const Market = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }, []);

  const { width, height } = useWindowSize();

  const backgroundStyle = (() => {
    if (width >= 1366) {
      return {};
    }

    if (width >= 1024) {
      return {};
    }

    if (width >= 600) {
      return {};
    }

    if (width <= 1000) {
      return {
        backgroundPosition: "center center, center 75%",
        backgroundSize: "100%, contain 100%",
      };
    }
  })();

  return (
    <Layout>
      <ImageBackground
        tag={"main"}
        fluid={[
          `linear-gradient(90deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.8) 100%)`,
          Image().market,
        ]}
        style={{
          ...backgroundStyle,
          backgroundPositionY: "",
          backgroundPositionX: "",
        }}
        css={css`
          margin-top: calc(-47px * 3);
          padding-top: calc(47px * 3);
          height: calc(100vh - 47px * 3);
          transform: skew(0deg, -5deg);
          background-size: 100%, 85%;
          background-position: center, center 90%;

          .content {
            height: 100%;
            transform: skew(0deg, 5deg);
            font-family: "Brandon Grotesque", sans-serif;
            color: white;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            h1 {
              font-size: 70px;
              text-align: center;
              line-height: 1;
            }

            .box {
              background-color: rgba(0, 0, 0, 0.8);
              padding: 1rem 2rem;
              border: 2px solid white;

              p {
                margin: 0;
              }
            }
          }

          @media (max-width: 768px) {
            background-position: center, center;
          }
        `}
        className={"market-bg"}
      >
        <div className="content">
          <h1>Where will your travels take you?</h1>
        </div>
        <ImageBackground
          tag={"div"}
          fluid={Image().pattern2}
          css={css`
            height: 25px;
          `}
        />
      </ImageBackground>
      <MainDiv>
        <div
          css={css`
            margin-top: 60px;
            display: grid;
            font-family: "Brandon Grotesque", sans-serif;
            place-content: center;
            grid-template-areas:
              "paragraph-1 image"
              "paragraph-2 image"
              "paragraph-2 button"
              "paragraph-3 paragraph-3";

            grid-column-gap: 20px;

            p {
              font-size: 30px;
            }

            @media screen and (max-width: 1024px) {
              grid-template-areas:
                "paragraph-1"
                "paragraph-2"
                "paragraph-3"
                "image"
                "button";

              p {
                text-align: center;
              }
            }
          `}
        >
          <div
            css={css`
              grid-area: paragraph-1;
            `}
          >
            <p>
              Let us take you on an adventure through Zambian lands rich in
              artistry and life.
            </p>
          </div>

          <div
            css={css`
              grid-area: paragraph-2;
            `}
          >
            <p>
              Where nomads wander and roam paths filled with vibrant offerings.
            </p>
          </div>

          <div
            css={css`
              grid-area: paragraph-2;
            `}
          >
            <p>
              Where nomads wander and roam paths filled with vibrant offerings.
            </p>

            <p>
              A journey that will nourish the soul, remedy the body and inspire
              the drifting wayfarer.
            </p>
          </div>

          <div
            css={css`
              grid-area: paragraph-3;
            `}
          >
            <p>
              The Market at NOMAD allows travellers to be immersed in the best
              of Zambian culture. Purchase local crafts, travel essentials and
              health necessities while picking up a quick bite.
            </p>
          </div>

          <div
            css={css`
              grid-area: image;

              .afri-logo {
                height: 250px;
              }
            `}
          >
            <a href="">
              <StaticImage
                className={"afri-logo"}
                src={"../images/afri.png"}
                alt={"africrafted-logo"}
              />
            </a>
          </div>

          <div
            css={css`
              grid-area: button;
              .box {
                background-color: rgba(0, 0, 0, 0.9);
                padding: 1rem 2rem;
                text-align: center;
                cursor: pointer;

                p {
                  margin: 0;
                  color: white;
                }
              }
            `}
          >
            <motion.div
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              className="box"
              onClick={() => {}}
            >
              <p>Order directly from our market partner!</p>
            </motion.div>
          </div>
        </div>
      </MainDiv>
    </Layout>
  );
};

export default Market;
